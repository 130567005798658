<template>
  <div class="bg-gray-50 pb-20">
    <div class="relative sm:py-16">
      <div aria-hidden="true" class="hidden sm:block">
        <div class="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl" />
        <svg
          class="absolute top-8 left-1/2 -ml-3"
          width="404"
          height="392"
          fill="none"
          viewBox="0 0 404 392"
        >
          <defs>
            <pattern
              id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                class="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="392"
            fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
          />
        </svg>
      </div>
      <div
        class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8"
      >
        <div
          class="
            relative
            rounded-2xl
            px-6
            py-6
            bg-indigo-500
            shadow-xl
            sm:px-12 sm:py-10
          "
        >
          <div
            aria-hidden="true"
            class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
          >
            <svg
              class="absolute inset-0 h-full w-full"
              preserveAspectRatio="xMidYMid slice"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 1463 360"
            >
              <path
                class="text-indigo-400 text-opacity-40"
                fill="currentColor"
                d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
              />
              <path
                class="text-indigo-700 text-opacity-40"
                fill="currentColor"
                d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
              />
            </svg>
          </div>
          <div class="relative">
            <div class="sm:text-center">
              <h2
                class="
                  text-3xl
                  font-extrabold
                  text-white
                  tracking-tight
                  sm:text-4xl
                "
              >
                Please enter a location
              </h2>
            </div>
            <form
              v-on:submit.prevent="onSubmit"
              class="mt-6 sm:mx-auto sm:max-w-2xl"
            >
              <div class="min-w-0 flex-1 relative">
                <!-- <label for="cta-email" class="sr-only">Email address</label> -->
                <label for="search-location" class="sr-only"></label>
                <!-- note: this input field had this id originally id="cta-email" type="email ref="origin"" -->
                <input
                  id="search-location"
                  ref="autocomplete"
                  type="text"
                  class="
                    block
                    w-full
                    border border-transparent
                    rounded-md
                    px-5
                    py-3
                    text-base text-gray-900
                    placeholder-gray-500
                    shadow-sm
                    focus:outline-none
                    focus:border-transparent
                    focus:ring-2
                    focus:ring-white
                    focus:ring-offset-2
                    focus:ring-offset-indigo-600
                    search-location
                  "
                  placeholder="Enter an address, city or postal code."
                  v-on:input="updateSearchLocation($event)"
                  v-on:blur="updateSearchLocation($event)"
                />
                <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                  <button
                    class="
                      inline-flex
                      items-center
                      border border-gray-200
                      rounded
                      px-2
                      text-sm
                      font-sans font-medium
                      text-gray-400
                      cursor-pointer
                      hover:bg-indigo-600
                      group
                      location-button
                    "
                     @click="getLocation"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      width="16"
                      height="16"
                      stroke="#9ca3af"
                      stroke-width="2"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="css-i6dzq1"
                    >
                      <path
                        d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"
                      ></path>
                      <circle cx="12" cy="10" r="3"></circle>
                    </svg>
                  </button>
                </div>
              </div>

              <div
                class="min-w-0 grid grid-cols-2 relative mt-6 gap-4"
                v-if="categoryData && subCategoryData"
              >
                <div>
                  <p class="block text-sm font-medium text-white text-lg">
                    I am looking for...
                  </p>
                  <select class="text-base" id="selectCat" @change="filterSubCats($event.target.value);updateCategory($event.target.value);">
                    <option 
                      v-for="category in categoryData" 
                      :key="category.contentItemId"
                      :value="category.category">
                        {{category.category}}
                    </option>
                  </select>
                </div>
                
                <div>
                  <p class="block text-sm font-medium text-white text-lg">
                    Specifically...
                  </p>
                  <select class="text-base" id="selectSubcat" @change="updateSubcategory($event.target.value)">
                    <option
                      v-for="subcategory in modifiedSubcategories"
                      :key="subcategory.contentItemId"
                      :value="subcategory.category">
                        {{subcategory.category}}
                      </option>
                  </select>
                </div>
                <!-- <Listbox as="div" v-model="defaultSelectedCategory" class="">
                  <ListboxLabel
                    class="block text-sm font-medium text-white text-lg"
                  >
                    I am looking for...
                  </ListboxLabel>
                  <div class="mt-1 relative">
                    <ListboxButton
                      class="
                        bg-white
                        relative
                        w-full
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        pl-3
                        pr-10
                        px-5
                        py-3
                        text-base text-left
                        shadow-sm
                        cursor-default
                        focus:outline-none
                        focus:ring-1
                        focus:ring-indigo-500
                        focus:border-indigo-500
                        sm:text-sm
                      "
                    >
                      <span class="block truncate">{{
                        defaultSelectedCategory.category
                      }}</span>
                      <span
                        class="
                          absolute
                          inset-y-0
                          right-0
                          flex
                          items-center
                          pr-2
                          pointer-events-none
                        "
                      >
                        <SelectorIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition ease-in duration-100"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions
                        class="
                          absolute
                          z-10
                          mt-1
                          w-full
                          bg-white
                          shadow-lg
                          max-h-60
                          rounded-md
                          py-1
                          text-base
                          ring-1 ring-black ring-opacity-5
                          overflow-auto
                          focus:outline-none
                          sm:text-sm
                        "
                      >
                        <ListboxOption
                          ref="categoryOption"
                          as="template"
                          v-for="category in categoryData"
                          :key="category.contentItemId"
                          :value="category"
                          v-slot="{ active, selected }"
                          v-on:click="
                            filterSubCats(category.category);
                            updateCategory(category.category);
                          "
                        >
                          <li
                            :class="[
                              active
                                ? 'text-white bg-indigo-600'
                                : 'text-gray-900',
                              'cursor-default select-none relative py-2 pl-3 pr-9',
                            ]"
                          >
                            <span
                              :class="[
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate',
                              ]"
                            >
                              {{ category.category }}
                            </span>

                            <span
                              v-if="selected"
                              :class="[
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              ]"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
                <Listbox as="div" v-model="defaultSelectedSubcategory" class="">
                  <ListboxLabel
                    class="block text-sm font-medium text-white text-lg"
                  >
                    Specifically...
                  </ListboxLabel>
                  <div class="mt-1 relative">
                    <ListboxButton
                      class="
                        bg-white
                        relative
                        w-full
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        pl-3
                        pr-10
                        px-5
                        py-3
                        text-base text-left
                        shadow-sm
                        cursor-default
                        focus:outline-none
                        focus:ring-1
                        focus:ring-indigo-500
                        focus:border-indigo-500
                        sm:text-sm
                      "
                    >
                      <span class="block truncate">{{
                        defaultSelectedSubcategory.category
                      }}</span>
                      <span
                        class="
                          absolute
                          inset-y-0
                          right-0
                          flex
                          items-center
                          pr-2
                          pointer-events-none
                        "
                      >
                        <SelectorIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition ease-in duration-100"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions
                        class="
                          absolute
                          z-10
                          mt-1
                          w-full
                          bg-white
                          shadow-lg
                          max-h-60
                          rounded-md
                          py-1
                          text-base
                          ring-1 ring-black ring-opacity-5
                          overflow-auto
                          focus:outline-none
                          sm:text-sm
                        "
                      >
                        <ListboxOption
                          as="template"
                          v-for="subcategory in modifiedSubcategories"
                          :key="subcategory.contentItemId"
                          :value="subcategory"
                          v-slot="{ active, selected }"
                          v-on:click="updateSubcategory(subcategory.category)"
                        >
                          <li
                            :class="[
                              active
                                ? 'text-white bg-indigo-600'
                                : 'text-gray-900',
                              'cursor-default select-none relative py-2 pl-3 pr-9',
                            ]"
                          >
                            <span
                              :class="[
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate',
                              ]"
                            >
                              {{ subcategory.category }}
                            </span>

                            <span
                              v-if="selected"
                              :class="[
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              ]"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox> -->
              </div>
              <div class="mt-6">
                <button
                  type="submit"
                  class="
                    block
                    w-full
                    rounded-md
                    border border-transparent
                    px-5
                    py-3
                    bg-indigo-700
                    text-base
                    font-medium
                    text-white
                    shadow
                    focus:outline-none
                    focus:ring-2
                    focus:ring-white
                    focus:ring-offset-2
                    focus:ring-offset-indigo-600
                    sm:px-10
                  "
                  v-on:click="search"
                  :disabled="isSearchDisabled"
                  v-bind:class="[
                    isSearchDisabled
                      ? 'text-opacity-90 cursor-not-allowed'
                      : 'hover:bg-indigo-800',
                  ]"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 -mt-1 inline-block mr-1" fill="none" viewBox="0 0 24 24" stroke="#fff">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg> 
                  Search
                </button>
              </div>
              <!--reset button -->
              <div class="mt-2">
                <button
                  type="button"
                  class="
                    block
                    w-full
                    rounded-md
                    border border-transparent
                    px-5
                    py-2
                    bg-gray-800  
                    text-base
                    font-medium
                    text-white
                    hover:bg-gray-900
                    focus:outline-none
                    focus:ring-2
                    focus:ring-white
                    focus:ring-offset-2
                    focus:ring-offset-indigo-600
                    sm:px-10
                  "
                  v-on:click="reset"
                >
                  Reset Filters
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8"
    >
      <LoadingSpinner v-if="isLoading" class="justify-center items-center"/>
      <ul
        role="list"
        class="
          grid grid-cols-1
          gap-6
          sm:grid-cols-2
          md:grid-cols-3
          lg:grid-cols-3
          mx-auto
        "
        v-if="modifiedListOfUsers && !isLoading"
      >
        <li
          v-for="person in modifiedListOfUsers.filter(user => (!user.IsAccountPrivate && user.FirstName != null))"
          :key="person.Email"
          class="
            col-span-1
            flex flex-col
            text-center
            bg-white
            rounded-lg
            shadow-xl
            divide-y divide-gray-200
          "
        >
          <div class="flex-1 flex flex-col p-8">
            <img
              class="w-32 h-32 flex-shrink-0 mx-auto rounded-full object-cover"
              :src="person.profileImageUrl"
              alt=""
            />
            <h3 class="mt-6 text-gray-900 text-sm font-medium">
              {{ person.ShowDisplayName ? person.DisplayName : person.FirstName + " " + person.LastName }}
            </h3>
            <dl class="mt-1 flex-grow flex flex-col justify-between">
              <dt class="sr-only">Location</dt>
              <dd class="text-gray-500 text-sm">{{ person.City ? person.City : "City" }}, {{ person.Province ? person.Province: "Province" }}</dd>
              <dt class="sr-only">Role</dt>
              <dd class="mt-3">
                <span
                  v-for="userCat in splitCategories(person.Categories)"
                  :key="userCat"
                  class="
                    px-2
                    py-1
                    text-green-800 text-xs
                    font-medium
                    bg-green-100
                    rounded-full
                    inline-block mx-1
                  "
                  >{{ userCat }}</span
                >
              </dd>
            </dl>
          </div>
          <div>
            <div class="-mt-px flex divide-x divide-gray-200">
            </div>
            <div class="-mt-px flex divide-x divide-gray-200">
              <div class="-ml-px w-full flex-1 flex">
                <router-link
                  :to="'/profile/' + person.Username"
                  style="border-top-left-radius: 0; border-top-right-radius: 0"
                  type="submit"
                  class="
                    block
                    w-full
                    rounded-md
                    border border-transparent
                    px-5
                    py-3
                    bg-gradient-to-r
                    from-indigo-500
                    to-indigo-700
                    bg-origin-border
                    text-base
                    font-medium
                    text-white
                    shadow
                    hover:bg-indigo-400
                    focus:outline-none
                    focus:ring-2
                    focus:ring-white
                    focus:ring-offset-2
                    focus:ring-offset-indigo-600
                    sm:px-10
                  "
                  >View Profile</router-link
                >
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div>
        <div class="text-center" v-if="!foundResults">
          <h3 class="
              mt-1
              text-4xl
              font-extrabold
              text-gray-900
              sm:text-2xl
              normal-case	
              sm:tracking-tight
              lg:text-5xl
              text-center ">No results found...</h3>  
              <div class="relative text-lg text-gray-700 font-medium mt-4 mb-4">
                <p class="relative text-center mb-5">Please try again with another location or category.</p>
                </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import {
  CheckIcon,
  SelectorIcon,
  MailIcon,
  PhoneIcon,
} from "@heroicons/vue/solid";
import LoadingSpinner from "../components/LoadingSpinner.vue";
import { OcContentService } from "../services/ocContentService";
import { NopService } from "../services/nopService"
import { UploadcareService } from "../services/uploadcareService"
import { CookieService } from '../services/cookieService';
import axios from "axios";
import Button from '../components/Button.vue';

const categories = [
  { id: 1, name: "-- Please Select --" },
  { id: 2, name: "Comedians" },
  { id: 3, name: "DJs" },
  { id: 4, name: "Dance" },
  { id: 5, name: "Special Acts" },
  { id: 6, name: "Actors" },
  { id: 7, name: "Musicians" },
];
const subcategories = [
  { id: 1, name: "-- Please Select --" },
  { id: 2, name: "Stand Up Comedians" },
  { id: 3, name: "Adult Rated" },
  { id: 4, name: "Comedy Magicians" },
  { id: 5, name: "Emcees" },
  { id: 6, name: "Corporate Comedians" },
];

const profiles = [
  {
    name: "Toronto Cooper 1",
    location: "Toronto, On",
    role: "Comedy Magicians",
    email: "janecooper@example.com",
    telephone: "+1-202-555-0170",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  },
  {
    name: "John Cooper",
    location: "Toronto, On",
    role: "Adult Rated",
    email: "johncooper@example.com",
    telephone: "+1-202-555-0170",
    imageUrl:
      "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
  },
  {
    name: "Mississauga Cooper",
    location: "Mississauga, On",
    role: "Emcees",
    email: "janecooper@example.com",
    telephone: "+1-202-555-0170",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  },
  {
    name: "Toronto Cooper 2",
    location: "Toronto, On",
    role: "Musicians",
    email: "johncooper@example.com",
    telephone: "+1-202-555-0170",
    imageUrl:
      "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
  },
  {
    name: "Toronto Cooper 3",
    location: "Toronto, On",
    role: "Comedians",
    email: "janecooper@example.com",
    telephone: "+1-202-555-0170",
    imageUrl: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  },
  {
    name: "London Cooper",
    location: "London, On",
    role: "Musicians",
    email: "johncooper@example.com",
    telephone: "+1-202-555-0170",
    imageUrl:
      "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
  },
  // More categories...
];

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
    MailIcon,
    PhoneIcon,
    LoadingSpinner,
    Button,
  },
  setup() {
    const selected = ref(categories[0]);
    const selected2 = ref(subcategories[0]);
    return {
      categories,
      subcategories,
      selected,
      selected2,
      profiles,
    };
  },
  data() {
    return {
      categoryData: null, //the original data
      subCategoryData: null, //the original data
      modifiedSubcategories: null, //we want to modify this one and keep the original data intact
      defaultSelectedCategory: null,
      defaultSelectedSubcategory: null,
      searchLocation: null,
      selectedCategory: null,
      selectedSubcategory: null,
      isSearchDisabled: true,
      modifiedListOfUsers: null,
      listboxSubcats: 0,
      testcat: false,
      listOfUsers: null,
      isLoading: true,
      foundResults: true,
    };
  },
  async created() {
    //need to do this to check whether the nav bar needs to be updated
    if (!CookieService.getLoginCookie()) {
      this.emitter.emit("logged-in", false);
    }
    await this.getListOfUsers();
    await this.getCategoryData();
    await this.getSubcategoryData();
    let location = this.$route.params.location;
    let category = this.$route.params.category;
    let subcategory = this.$route.params.subcategory;
    if (location && category) {
      subcategory = subcategory == "null" ? process.env.VUE_APP_DEFAULT_SELECTION : subcategory;
      await this.searchFromParams(location, category, subcategory);
      this.$refs.autocomplete.value = location;
      this.searchLocation = location;

      this.selectedCategory = category;
      this.selectedSubcategory = subcategory;
      await this.filterSubCats(category);
      let mainCatSelect = document.getElementById("selectCat");
      let subcatSelect = document.getElementById("selectSubcat");
      mainCatSelect.value = category;
      subcatSelect.value = subcategory;
    }
  },
  async mounted() {
    //allows for autocomplete functionality in location search
    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete,
      { types: ["geocode"], componentRestrictions: { country: "ca" }, }
    );
  },
  methods: {
    async getListOfUsers() {
      let resp = await NopService.GetAllCustomers();
      console.log("resp: " + resp);
      let finalList = []
      for (var person of resp.data.Data.Data) {
        let modifiedPerson = {...person, profileImageUrl: ""}
        modifiedPerson.profileImageUrl = await this.getUploadcareImage(person.ProfileImageUUID)
        finalList.push(modifiedPerson);
      }
      this.listOfUsers = finalList;
      this.modifiedListOfUsers = this.listOfUsers
      //this.splitCategories(this.listOfUsers[0])
      this.isLoading = false;
    },
    async search() {
      this.isLoading = true;
      this.searchLocation = this.$refs.autocomplete.value;
      let dests = [];
      this.modifiedListOfUsers = [];
      this.foundResults = true;
      this.listOfUsers.forEach((p) => {
        //dests.push(p.City + ", " + p.Province);
        dests.push(p.City + ", " + p.Province + "+" + p.ZipPostalCode);
      });
      // eslint-disable-next-line no-undef
      var service = new google.maps.DistanceMatrixService();
      await service.getDistanceMatrix(
        {
          origins: [this.searchLocation],
          destinations: dests,
          travelMode: "DRIVING",
        },
        (response) => {
          response.rows[0].elements.forEach((value, i) => {
            if (value.distance.value <= (this.listOfUsers[i].Radius*1000)) {//process.env.VUE_APP_MAX_DISTANCE_M
              // if (this.listOfUsers[i].Categories.toLowerCase().includes(this.selectedSubcategory.toLowerCase())) {
              //   this.modifiedListOfUsers.push(this.listOfUsers[i]);
              // }
              // subcategories are optional
              if (this.selectedSubcategory && this.selectedSubcategory !== process.env.VUE_APP_DEFAULT_SELECTION) {
                if (this.listOfUsers[i].Categories.toLowerCase().includes(this.selectedSubcategory.toLowerCase())) {
                  this.modifiedListOfUsers.push(this.listOfUsers[i]);
                }
              }
              else {
                if (this.listOfUsers[i].PrimaryCategory.toLowerCase().includes(this.selectedCategory.toLowerCase())) {
                  this.modifiedListOfUsers.push(this.listOfUsers[i]);
                }
              }
            }
          });
        }
      );
      this.foundResults = this.modifiedListOfUsers.length > 0;
      this.isLoading = false;

      //this.$router.push({params: {location: this.searchLocation, category: this.selectedCategory, subcategory: this.selectedSubcategory}})
      this.$router.push({ path: `/search/${this.searchLocation}/${this.selectedCategory}/${this.selectedSubcategory}/` })
    },
    async searchFromParams(location, category, subcategory) {
      this.isLoading = true;
      let dests = [];
      this.modifiedListOfUsers = [];
      this.foundResults = true;
      this.listOfUsers.forEach((p) => {
        //dests.push(p.City + ", " + p.Province);
        dests.push(p.City + ", " + p.Province + "+" + p.ZipPostalCode);
      });
      // eslint-disable-next-line no-undef
      var service = new google.maps.DistanceMatrixService();
      await service.getDistanceMatrix(
        {
          origins: [location],
          destinations: dests,
          travelMode: "DRIVING",
        },
        (response) => {
          response.rows[0].elements.forEach((value, i) => {
            if (value.distance.value <= (this.listOfUsers[i].Radius*1000)) {//process.env.VUE_APP_MAX_DISTANCE_M
              // subcategories are optional
              if (subcategory && subcategory !== process.env.VUE_APP_DEFAULT_SELECTION) {
                if (this.listOfUsers[i].Categories.toLowerCase().includes(subcategory.toLowerCase())) {
                  this.modifiedListOfUsers.push(this.listOfUsers[i]);
                }
              }
              else {
                if (this.listOfUsers[i].PrimaryCategory.toLowerCase().includes(category.toLowerCase())) {
                  this.modifiedListOfUsers.push(this.listOfUsers[i]);
                }
              }
            }
          });
        }
      );
      this.foundResults = this.modifiedListOfUsers.length > 0;
      this.isLoading = false;
    },
    // called on component load, gets the list of categories from oc
    async getCategoryData() {
      // have to copy result to temp array for it to be mutable (weird js malarkey)
      let toSort = [...(await OcContentService.fetchMainCategories())];
      // sort the data by alphabetical so that the default option is on top
      this.categoryData = toSort.sort((a, b) =>
        a.category < b.category ? -1 : a.category > b.category ? 1 : 0
      );
      // makes the initial selection be the "please select" option
      this.defaultSelectedCategory = this.categoryData.filter(
        (cats) => cats.category == process.env.VUE_APP_DEFAULT_SELECTION
      )[0];
    },
    // called on component load, gets the list of subcategories from oc
    async getSubcategoryData() {
      // have to copy result to temp array for it to be mutable (weird js malarkey)
      let toSort = [...(await OcContentService.fetchSubCategories())];
      // sort the data by alphabetical so that the default option is on top
      this.subCategoryData = toSort.sort((a, b) =>
        a.category < b.category ? -1 : a.category > b.category ? 1 : 0
      );
      // makes the initial selection be the "please select" option
      this.defaultSelectedSubcategory = this.subCategoryData.filter(
        (subCats) => subCats.category == process.env.VUE_APP_DEFAULT_SELECTION
      )[0];
      this.modifiedSubcategories = [this.defaultSelectedSubcategory];
    },
    filterSubCats(parent) {
      this.modifiedSubcategories = this.subCategoryData.filter(
        (subcat) =>
          subcat.parentCategory.contentItems[0]
            ? subcat.parentCategory.contentItems[0].category == parent
            : true // captures the default empty category
      );
    },
    updateSearchLocation(event) {
      this.searchLocation = event.target.value;
      this.validateSearchFields();
    },
    updateCategory(value) {
      this.selectedCategory = value;
      this.validateSearchFields();
    },
    updateSubcategory(value) {
      this.selectedSubcategory = value;
      this.validateSearchFields();
    },
    validateSearchFields() {
      this.isSearchDisabled = !(
        this.selectedCategory !== process.env.VUE_APP_DEFAULT_SELECTION &&
        this.selectedCategory &&
        // this.selectedSubcategory !== process.env.VUE_APP_DEFAULT_SELECTION &&
        // this.selectedSubcategory &&
        this.searchLocation
      );
    },
    reset() {
      this.isLoading = true;
      this.modifiedListOfUsers = this.listOfUsers;
      this.$refs.autocomplete.value = "";
      this.selectedCategory = process.env.VUE_APP_DEFAULT_SELECTION;
      this.selectedSubcategory = process.env.VUE_APP_DEFAULT_SELECTION;
      this.searchLocation = "";

      let mainCatSelect = document.getElementById("selectCat");
      mainCatSelect.value = process.env.VUE_APP_DEFAULT_SELECTION;
      let subcatSelect = document.getElementById("selectSubcat");
      subcatSelect.value = process.env.VUE_APP_DEFAULT_SELECTION;
      this.modifiedSubcategories = [this.defaultSelectedSubcategory];
      this.isSearchDisabled = true;
      this.isLoading = false;
      this.foundResults = true;
      this.$router.push({ path: '/search/'});
    },
    async getUploadcareImage(uuid) {
      let resp = UploadcareService.buildImageUrl(uuid, "-/resize/x200/");
      return resp
    },
    splitCategories(categories) {
      let cats = []
      if (categories) {
        if (categories) {
          cats = categories.split(",").filter(cat => cat !== "")
        }
      }
      return cats 
    },
    async getLocation() {
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };

      function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
      }

    await navigator.geolocation.getCurrentPosition(async (pos) => {
        let crd = pos.coords;
        let api = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${crd.latitude},${crd.longitude}&key=${process.env.VUE_APP_GEOCODING_KEY}`
        let resp = await axios.get(api)
        if (!resp.data.error_message) {
          this.$refs.autocomplete.value = resp.data.results[0].formatted_address;
          this.searchLocation = resp.data.results[0].formatted_address;
        }
      }, error, options);
    }
  },
};
</script>
